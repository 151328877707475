import React from 'react'

const Logo = () => (
<svg viewBox="0 0 490 490">
<title>Marigold-photography</title>
<g fill="#D4AF37" >
		<path d="M496,256c0-23.176-12.312-60.704-51.968-81.304c15.184-38.304,4.536-76.864-15.016-96.4
			c-15.416-15.424-38.328-25.024-61.288-25.68c-9.136-0.24-20.344,0.872-32.944,4.896C318.416,19.728,283.64,0,256,0
			c-23.176,0-60.704,12.312-81.296,51.96c-38.288-15.184-76.856-4.528-96.4,15.024C62.88,82.4,53.28,105.312,52.624,128.272
			c-0.256,9.112,0.848,20.328,4.888,32.952C19.72,177.584,0,212.36,0,240c0,23.176,12.312,60.704,51.96,81.296
			c-15.176,38.304-4.536,76.864,15.024,96.4c15.416,15.424,38.328,25.024,61.288,25.68c0.84,0.024,1.704,0.04,2.576,0.04
			c8.616,0,18.912-1.264,30.376-4.928C177.584,476.28,212.36,496,240,496c23.2,0,60.784-12.344,81.36-52.096
			c12.592,4.984,25.208,7.336,37.168,7.336c24.4,0,46.064-9.112,59.168-22.224c15.424-15.416,25.024-38.328,25.68-61.288
			c0.256-9.112-0.848-20.328-4.888-32.952C476.28,318.416,496,283.64,496,256z M367.272,68.608c28.12,0.808,45.712,16.272,50.432,21
			c15.32,15.312,23.84,47.32,11.6,78.824C414.608,163.232,397.12,160,376,160c-34.568,0-58.76,6.056-75.544,13.72
			c2.976-3.472,6.216-7.016,9.768-10.568c20.968-20.968,53.128-31.904,53.448-32.016l-5.064-15.168
			c-1.464,0.488-36.008,12.176-59.696,35.872c-17.704,17.704-28.688,35.264-33.152,43.104C260.176,193.056,254.216,192,248,192
			c-1.76,0-3.488,0.104-5.208,0.264c-0.936-13.288,2.872-43.76,44.8-85.688C313.552,80.616,340.4,68.032,367.272,68.608z M288,248
			c0,22.056-17.944,40-40,40c-22.056,0-40-17.944-40-40c0-22.056,17.944-40,40-40C270.056,208,288,225.944,288,248z M256,16
			c21.616,0,50.032,16.736,63.672,47.56c-13.52,6.496-28.184,16.496-43.392,31.704c-24.44,24.448-37.272,45.832-43.712,63.12
			C232.216,153.832,232,149.032,232,144c0-29.656,15.008-60.128,15.152-60.432l-14.312-7.152C232.152,77.8,216,110.504,216,144
			c0,25.064,4.672,45.256,7.056,53.936c-6.992,3.504-13.184,8.376-18.136,14.336C194.856,203.544,176,179.304,176,120
			C176,37.904,226.288,16,256,16z M68.608,128.72c0.808-28.112,16.272-45.704,21-50.424c15.312-15.304,47.312-23.832,78.816-11.6
			C163.232,81.392,160,98.88,160,120c0,34.568,6.048,58.752,13.72,75.544c-3.472-2.976-7.016-6.216-10.568-9.768
			c-20.968-20.968-31.904-53.128-32.016-53.448l-15.176,5.064c0.488,1.464,12.184,36.008,35.872,59.696
			c17.704,17.704,35.264,28.688,43.104,33.144C193.056,235.824,192,241.784,192,248c0,1.76,0.104,3.496,0.264,5.208
			c-13.288,0.944-43.752-2.88-85.688-44.808C80.616,182.44,67.84,155.632,68.608,128.72z M16,240
			c0-21.616,16.736-50.032,47.56-63.672c6.504,13.528,16.504,28.192,31.704,43.392c24.488,24.488,45.888,37.272,63.192,43.712
			c-4.568,0.344-9.4,0.568-14.456,0.568c-29.656,0-60.128-15.008-60.432-15.16l-7.152,14.312C77.8,263.84,110.496,280,144,280
			c25.064,0,45.256-4.672,53.936-7.056c3.504,6.992,8.376,13.184,14.336,18.136C203.544,301.144,179.304,320,120,320
			C37.904,320,16,269.712,16,240z M128.728,427.392c-28.12-0.808-45.712-16.272-50.432-21c-15.312-15.312-23.84-47.32-11.6-78.824
			C81.392,332.768,98.88,336,120,336c34.568,0,58.752-6.056,75.544-13.72c-2.976,3.464-6.216,7.016-9.768,10.568
			c-20.968,20.968-53.128,31.904-53.448,32.016l5.064,15.176c1.464-0.488,36.008-12.184,59.696-35.872
			c17.704-17.704,28.688-35.264,33.152-43.104c5.584,1.88,11.544,2.936,17.76,2.936c1.76,0,3.488-0.104,5.208-0.264
			c0.936,13.288-2.88,43.76-44.8,85.688C182.44,415.384,155.68,427.864,128.728,427.392z M240,480
			c-21.608,0-50.008-16.72-63.664-47.536c13.52-6.504,28.192-16.544,43.376-31.736c24.44-24.448,37.272-45.832,43.72-63.12
			c0.344,4.56,0.568,9.36,0.568,14.392c0,29.656-15.008,60.128-15.16,60.432l14.312,7.152C263.84,418.2,280,385.504,280,352
			c0-25.064-4.672-45.256-7.056-53.936c6.992-3.504,13.184-8.376,18.136-14.336C301.144,292.456,320,316.696,320,376
			C320,458.096,269.712,480,240,480z M427.392,367.272c-0.808,28.12-16.272,45.712-21,50.432
			c-15.304,15.312-47.296,23.848-78.824,11.6C332.768,414.608,336,397.12,336,376c0-34.568-6.056-58.76-13.72-75.544
			c3.464,2.976,7.016,6.216,10.568,9.768c20.968,20.968,31.904,53.128,32.016,53.448l15.168-5.064
			c-0.488-1.464-12.176-36.008-35.872-59.696c-17.704-17.704-35.264-28.688-43.104-33.152C302.944,260.176,304,254.216,304,248
			c0-1.76-0.104-3.488-0.264-5.208c13.288-0.936,43.752,2.88,85.688,44.8C415.384,313.552,428.16,340.36,427.392,367.272z
			 M432.44,319.672c-6.504-13.528-16.504-28.192-31.704-43.392c-24.44-24.44-45.824-37.272-63.12-43.72
			c4.552-0.336,9.352-0.56,14.384-0.56c29.656,0,60.128,15.008,60.432,15.16l7.152-14.312C418.2,232.16,385.496,216,352,216
			c-25.064,0-45.256,4.672-53.936,7.056c-3.504-6.992-8.376-13.184-14.336-18.136C292.456,194.856,316.696,176,376,176
			c82.096,0,104,50.288,104,80C480,277.616,463.264,306.032,432.44,319.672z"/>
	</g>

	
  </svg>
)

export default Logo
